import { SVGProps, memo } from 'react';
const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={props.width || "22"} height={props.height || "23"} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.13281" y="1" width="17" height="21" rx="1.21212" stroke="white" strokeWidth="1.8"/>
    <rect x="1" y="10" width="4" height="3" rx="1.21212" fill="#3DA7E7" stroke="white" strokeWidth="1.8"/>
    <rect x="1" y="4" width="4" height="3" rx="1.21212" fill="#3DA7E7" stroke="white" strokeWidth="1.8"/>
    <rect x="1" y="16" width="4" height="3" rx="1.21212" fill="#3DA7E7" stroke="white" strokeWidth="1.8"/>
    </svg>
);
export default memo(DashboardIcon);
