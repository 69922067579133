import { Avatar, Box, Link } from '@mui/material'
import React, { useEffect } from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '../../svg/InstagramIcon';
import { VerifiedRounded } from '@mui/icons-material';
import { fetchCoursesByCreator } from '../../utils/api';
import CardChatModal from './CardChatModal';
import Markdown from '../userChat/Markdown';

interface ResponseCardType {
    profile_data: any,
    response: String,
    id: number,
    response_time: number,
    user_id: String,
}

const ResponseCard = (props: ResponseCardType) => {
    const {profile_data,response, id, response_time, user_id} = props;
    const { linkedInURL, instagramURL, youtubeURL, userName } = profile_data || {};
    const [courseList, setCourseList] = React.useState(null);
    const [showIndividualCard, setShowIndividualCard] = React.useState(false);

    const getCoursesByExpert = async (user_id) => {
      try {
        let courses = await fetchCoursesByCreator(user_id);
        if (courses.length > 0) setCourseList(courses)
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
    const shortenResponse = true;

    const renderLinks = React.useCallback((link: string | undefined, platform: string) => {
        // Define the base URL for each platform
        const platformUrls = {
          linkedin: 'https://www.linkedin.com/in/',
          twitter: 'https://twitter.com/',
          youtube: 'https://www.youtube.com/@',
          tiktok: 'https://www.tiktok.com/@',
          instagram: 'https://www.instagram.com/',
        };

        if (link) {

          // Special handling for YouTube links
          if (platform === 'youtube') {
            // Check if the link is a full YouTube URL
            if (link.includes('youtube.com')) {
              return link;
            } else {
              return `${platformUrls.youtube}${link}`;
            }
          }
          // Check if the link already contains a full URL for the platform
          if (link.includes(platformUrls[platform])) {
            // Extract the user identifier from the link
            const userId = link.split(platformUrls[platform])[1];
            return `${platformUrls[platform]}${userId}`;
          } else {
            // If not a full URL, construct it using the base URL and provided user identifier
            switch (platform) {
              case 'linkedin':
                return `${platformUrls.linkedin}${link}`;
              case 'twitter':
                return `${platformUrls.twitter}${link}`;
              case 'youtube':
                return `${platformUrls.youtube}${link}`;
              case 'tiktok':
                return `${platformUrls.tiktok}${link}`;
              case 'instagram':
                return `${platformUrls.instagram}${link}`;
              default:
                return link;
            }
          }
        }

        return undefined;
      }, []);

      function truncateString(str) {
        if (str.length <= 300) {
          return str;
        } else {
          return str.slice(0, 300 - 3) + "...";
        }
      }

      useEffect(() => {
        getCoursesByExpert(user_id);
      }, [user_id])


  return (
    <Box className='query-response-item' padding={'10px'} bgcolor={id===0? '#efefef50':'#efefef70'} borderRadius={'10px'} border={id===0&&'2px solid #1d99ff'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} borderBottom={'1px solid #31313123'}>
            <Link
              padding={'5px'}
              display={'flex'}
              flexDirection={'row'}
              gap={'5px'}
              alignItems={'center'}
              href={`${profile_data.userName}/chat`}
              target='_blank'
              rel='noopener noreferrer'
              underline='none'
              color={'black'}
              sx={{
                transition: 'all 150ms ease',
                "&:hover": {
                  scale: '1.01',
                  transition: 'all 150ms ease',
                }
              }}
              >
              <Avatar
                  src={profile_data.profilePicUrl}
                  alt={profile_data.fullName}
                  sx={{
                  width: 35,
                  height: 35,
                  border: '2px solid #fff',
                  fontSize: '1.3rem'
                  }}
              >{profile_data.fullName ? profile_data.fullName[0] : ''}</Avatar>

              <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} >
                      <Box sx={{
                        textDecoration: 'underline #999999',
                        transition: 'all 150ms ease',
                      "&:hover": {
                        textDecoration: 'underline',
                        transition: 'all 150ms ease',
                      }}}>{profile_data.fullName}</Box>
                      {profile_data.verified &&
                      <VerifiedRounded sx={{color:'#00A3FF', fontSize: '17.5px'}}/>
                      }
                  </Box>
                  <Box>
                      <div style={{fontSize: '12px', color: '#636363'}}>{profile_data.occupation ? profile_data.occupation : ''}</div>
                  </Box>
              </Box>
            </Link>
            <Box display={'flex'} alignItems={'flex-end'}>
            {instagramURL && <a
                id={`href-linkedin-${userName}`}
                href={renderLinks(instagramURL, 'instagram')}
                target='_blank'
                rel='noopener noreferrer'
            >
                <InstagramIcon
                color={'instagramIcon'}
                size={'25px'}
                />
            </a>}
            {linkedInURL && <a
                id={`href-linkedin-${userName}`}
                href={renderLinks(linkedInURL, 'linkedin')}
                target='_blank'
                rel='noopener noreferrer'
            >
                <LinkedInIcon
                color={'linkedinIcon'}
                sx={{
                    width:'25px'
                }}
                />
            </a>}
            {youtubeURL && <a
                id={`href-youtube-${userName}`}
                href={renderLinks(youtubeURL, 'youtube')}
                target='_blank'
                rel='noopener noreferrer'
            >
                <YouTubeIcon
                color={'youtubeIcon'}
                sx={{
                    width:'25px'
                }}
                />
            </a>}
            </Box>
        </Box>
        <Box padding={'5px'} borderBottom={courseList && '1px solid #31313125'} onClick={() => setShowIndividualCard(true)} sx={{cursor: 'pointer'}}>
            <Markdown className={'markdown query-markdown-response-container'}>
                {shortenResponse ? truncateString(response): response}
            </Markdown>
            <Box fontSize={'11px'} color={'#999999'}>
                Took {response_time.toFixed(3)} secs to curate...
            </Box>
        </Box>
        {courseList &&
        <Box padding={'5px'} fontSize={'13px'} display={'flex'} flexDirection={'row'} gap={'10px'} alignItems={'center'}>
            <Box>Courses:</Box>
            <Box width={'100%'} padding={'0px 0px 5px 0px'} sx={{overflowX: 'scroll'}} display={'flex'} flexDirection={'row'} gap={'10px'} flexWrap={'nowrap'} alignItems={'center'} className="course-subsection">
                {courseList?.map((course) => {
                    return (
                        <Box padding={'5px'} bgcolor={'#e4f3ff'} borderRadius={'5px'} minWidth={'max-content'}>
                            <a
                            href={`courses/${course.course_id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            >{course.name}</a>
                        </Box>
                    );
                })}
            </Box>
        </Box>}
        <CardChatModal
          showIndividualCard={showIndividualCard}
          setShowIndividualCard={setShowIndividualCard}
          profile_data={profile_data}
          id={id}
          courseList={courseList}
          user_id={user_id}
          response={response}
          response_time={response_time}
          renderLinks={renderLinks}/>
    </Box>
  )
}

export default ResponseCard
