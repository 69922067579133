import { memo } from 'react';
const NielsenLogo = () => (
<svg width="132" height="20" viewBox="0 0 132 20" fill="none" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg">
<path d="M23.7578 3.35001V11.49C23.7591 11.5749 23.7824 11.6581 23.8254 11.7313C23.8685 11.8045 23.9298 11.8652 24.0034 11.9076C24.077 11.9499 24.1604 11.9724 24.2453 11.9728C24.3302 11.9733 24.4138 11.9516 24.4878 11.91L31.4878 7.84003C31.5603 7.79648 31.6203 7.73489 31.6619 7.66129C31.7036 7.58769 31.7254 7.50458 31.7254 7.42002C31.7254 7.33546 31.7036 7.25235 31.6619 7.17875C31.6203 7.10515 31.5603 7.04359 31.4878 7.00004L24.4878 2.93003C24.4138 2.88843 24.3302 2.86676 24.2453 2.86719C24.1604 2.86762 24.077 2.89012 24.0034 2.93247C23.9298 2.97482 23.8685 3.03557 23.8254 3.10877C23.7824 3.18197 23.7591 3.2651 23.7578 3.35001Z" fill="#782EFF"></path>
<path d="M8.0166 15.6101V7.48005C8.01845 7.39394 7.99709 7.30894 7.95477 7.23393C7.91245 7.15891 7.85075 7.09664 7.77609 7.05369C7.70144 7.01074 7.61658 6.98867 7.53046 6.98979C7.44433 6.99091 7.3601 7.01519 7.28659 7.06007L0.236603 11.1201C0.164248 11.1649 0.104547 11.2275 0.0631409 11.3019C0.0217347 11.3762 0 11.4599 0 11.5451C0 11.6302 0.0217347 11.7139 0.0631409 11.7883C0.104547 11.8627 0.164248 11.9252 0.236603 11.9701L7.28659 16.0301C7.3601 16.0749 7.44433 16.0992 7.53046 16.1003C7.61658 16.1014 7.70144 16.0794 7.77609 16.0364C7.85075 15.9935 7.91245 15.9312 7.95477 15.8562C7.99709 15.7812 8.01845 15.6962 8.0166 15.6101Z" fill="#F40953"></path>
<path d="M11.8672 10.2101V18.3401C11.8684 18.425 11.8917 18.5081 11.9348 18.5813C11.9778 18.6545 12.0392 18.7153 12.1128 18.7576C12.1864 18.8 12.2697 18.8225 12.3547 18.8229C12.4396 18.8233 12.5231 18.8017 12.5972 18.7601L19.5972 14.6901C19.6722 14.6486 19.7348 14.5877 19.7783 14.5138C19.8218 14.44 19.8448 14.3558 19.8448 14.2701C19.8448 14.1843 19.8218 14.1002 19.7783 14.0263C19.7348 13.9524 19.6722 13.8916 19.5972 13.8501L12.5972 9.78009C12.5224 9.73808 12.4379 9.71643 12.3522 9.71731C12.2664 9.71819 12.1824 9.74153 12.1085 9.78506C12.0346 9.82859 11.9734 9.89078 11.9311 9.96536C11.8887 10.0399 11.8667 10.1243 11.8672 10.2101Z" fill="#FF9408"></path>
<path d="M19.9058 8.73994V0.609934C19.9077 0.523825 19.8863 0.438796 19.844 0.36378C19.8017 0.288763 19.7399 0.226526 19.6653 0.183573C19.5906 0.140621 19.5058 0.11855 19.4197 0.119669C19.3336 0.120789 19.2493 0.145072 19.1758 0.189951L12.1758 4.24995C12.101 4.29287 12.0389 4.35477 11.9957 4.42939C11.9525 4.50401 11.9297 4.5887 11.9297 4.67494C11.9297 4.76117 11.9525 4.84589 11.9957 4.92051C12.0389 4.99513 12.101 5.05701 12.1758 5.09992L19.1758 9.15995C19.2493 9.20483 19.3336 9.22908 19.4197 9.2302C19.5058 9.23132 19.5906 9.20925 19.6653 9.1663C19.7399 9.12335 19.8017 9.06111 19.844 8.98609C19.8863 8.91108 19.9077 8.82605 19.9058 8.73994Z" fill="#39C4C3"></path>
<path d="M63.9156 18.84H61.8156C61.6697 18.84 61.5299 18.7821 61.4267 18.6789C61.3236 18.5758 61.2656 18.4359 61.2656 18.29V5.81003C61.2656 5.66416 61.3236 5.52426 61.4267 5.42111C61.5299 5.31797 61.6697 5.26001 61.8156 5.26001H63.9156C64.0598 5.26263 64.1971 5.32173 64.2981 5.42459C64.3991 5.52745 64.4557 5.66588 64.4556 5.81003V18.29C64.4557 18.4342 64.3991 18.5726 64.2981 18.6754C64.1971 18.7783 64.0598 18.8374 63.9156 18.84Z" fill="#00234A"></path>
<path d="M74.0169 3.67993C78.6069 3.67993 81.2069 6.93994 81.2069 11.4999V11.8699C81.2069 11.9422 81.1926 12.0137 81.165 12.0804C81.1374 12.1471 81.0968 12.2078 81.0458 12.2589C80.9947 12.3099 80.9341 12.3504 80.8673 12.3781C80.8006 12.4057 80.7291 12.4199 80.6569 12.4199H70.1269C70.0868 12.4192 70.0471 12.4275 70.0106 12.4441C69.9741 12.4606 69.9417 12.4851 69.9158 12.5158C69.89 12.5464 69.8713 12.5824 69.8611 12.6212C69.8509 12.66 69.8494 12.7005 69.8569 12.7399C70.0426 13.7519 70.5909 14.6615 71.399 15.2983C72.207 15.9352 73.2195 16.2557 74.2469 16.1999C75.6967 16.2065 77.0998 15.6878 78.1969 14.7399C78.2518 14.6923 78.3157 14.656 78.3848 14.6331C78.4539 14.6103 78.5268 14.6015 78.5994 14.6071C78.6719 14.6126 78.7427 14.6326 78.8075 14.6657C78.8723 14.6988 78.9298 14.7444 78.9769 14.7999L80.1969 16.2699C80.2876 16.3769 80.3331 16.5151 80.3238 16.6551C80.3145 16.795 80.251 16.9259 80.1469 17.0199C78.5478 18.4806 76.4416 19.2592 74.2769 19.1899C69.5369 19.1899 66.5469 16.0599 66.5469 11.4999C66.5469 7.14994 69.4169 3.67993 74.0169 3.67993ZM73.9569 6.52994C73.0215 6.45912 72.0933 6.73958 71.3536 7.31653C70.6139 7.89347 70.1159 8.72545 69.9569 9.64993C69.948 9.69054 69.9482 9.7326 69.9576 9.7731C69.967 9.8136 69.9853 9.85151 70.0111 9.88406C70.037 9.91662 70.0697 9.94303 70.1071 9.96133C70.1444 9.97964 70.1853 9.98941 70.2269 9.98993H77.4169C77.457 9.99062 77.4967 9.98237 77.5332 9.96579C77.5697 9.94921 77.602 9.92472 77.6279 9.89407C77.6538 9.86343 77.6725 9.82744 77.6827 9.78867C77.6929 9.74989 77.6943 9.70932 77.6869 9.66992C77.6006 8.75472 77.1557 7.91082 76.4494 7.32251C75.743 6.7342 74.8326 6.44928 73.9169 6.52994H73.9569Z" fill="#00234A"></path>
<path d="M109.328 3.67993C113.918 3.67993 116.518 6.93994 116.518 11.4999V11.8699C116.518 12.0158 116.46 12.1557 116.357 12.2589C116.254 12.362 116.114 12.4199 115.968 12.4199H105.478C105.437 12.4177 105.396 12.4248 105.358 12.4407C105.321 12.4566 105.287 12.4809 105.26 12.5117C105.233 12.5426 105.213 12.5791 105.203 12.6187C105.192 12.6582 105.19 12.6997 105.198 12.7399C105.388 13.7517 105.939 14.6602 106.748 15.2966C107.557 15.9329 108.57 16.2539 109.598 16.1999C111.048 16.2065 112.451 15.6878 113.548 14.7399C113.602 14.6925 113.665 14.6564 113.733 14.6338C113.802 14.6111 113.874 14.6023 113.946 14.6079C114.017 14.6135 114.087 14.6333 114.151 14.6663C114.215 14.6993 114.272 14.7447 114.318 14.7999L115.548 16.2699C115.645 16.3706 115.699 16.505 115.699 16.6449C115.699 16.7848 115.645 16.9193 115.548 17.0199C113.946 18.4821 111.836 19.2607 109.668 19.1899C104.938 19.1899 101.948 16.0599 101.948 11.4999C101.898 7.14994 104.768 3.67993 109.328 3.67993ZM109.268 6.52994C108.333 6.45912 107.404 6.73958 106.665 7.31653C105.925 7.89347 105.427 8.72545 105.268 9.64993C105.257 9.68988 105.256 9.73176 105.264 9.77234C105.272 9.81291 105.289 9.85109 105.314 9.88394C105.339 9.9168 105.372 9.94345 105.409 9.96182C105.446 9.9802 105.487 9.98981 105.528 9.98993H112.718C112.758 9.99034 112.798 9.98202 112.835 9.96552C112.872 9.94901 112.905 9.92472 112.931 9.89432C112.958 9.86392 112.978 9.82813 112.989 9.7894C113.001 9.75066 113.004 9.7099 112.998 9.66992C112.911 8.76223 112.472 7.92473 111.775 7.33752C111.077 6.75032 110.177 6.46033 109.268 6.52994Z" fill="#00234A"></path>
<path d="M86.0164 18.8399H83.8664C83.7942 18.8399 83.7227 18.8257 83.6559 18.7981C83.5892 18.7704 83.5286 18.7299 83.4775 18.6788C83.4264 18.6278 83.3859 18.5671 83.3583 18.5004C83.3306 18.4337 83.3164 18.3621 83.3164 18.2899V0.739929C83.3164 0.667702 83.3306 0.596179 83.3583 0.529449C83.3859 0.46272 83.4264 0.402085 83.4775 0.351013C83.5286 0.299941 83.5892 0.259421 83.6559 0.231781C83.7227 0.204141 83.7942 0.189941 83.8664 0.189941H86.0164C86.1623 0.189941 86.3022 0.247868 86.4053 0.351013C86.5085 0.454158 86.5664 0.59406 86.5664 0.739929V18.3099C86.5612 18.4523 86.501 18.5871 86.3984 18.686C86.2958 18.7848 86.1589 18.84 86.0164 18.8399Z" fill="#00234A"></path>
<path d="M94.537 3.68006C95.5704 3.61917 96.6041 3.79363 97.5603 4.19031C98.5165 4.58699 99.3701 5.19553 100.057 5.97006C100.104 6.02566 100.14 6.09005 100.162 6.15952C100.184 6.22898 100.192 6.30213 100.185 6.3747C100.179 6.44727 100.158 6.51781 100.124 6.58225C100.09 6.64668 100.043 6.70374 99.9869 6.75006L98.477 8.01004C98.3768 8.10426 98.2445 8.15674 98.107 8.15674C97.9695 8.15674 97.8371 8.10426 97.7369 8.01004C97.2862 7.56066 96.7489 7.20752 96.1576 6.97202C95.5663 6.73652 94.9332 6.62358 94.297 6.64005C92.817 6.64005 91.907 7.18006 91.907 8.12006C91.907 9.06006 92.907 9.37006 95.397 9.96006C98.397 10.6701 100.397 11.5301 100.397 14.5201C100.397 17.5101 98.207 19.2201 94.397 19.2201C93.2076 19.2656 92.0225 19.0532 90.9229 18.5975C89.8234 18.1418 88.8355 17.4536 88.027 16.5801C87.9454 16.4679 87.9076 16.3299 87.9204 16.1918C87.9333 16.0538 87.9961 15.9252 88.097 15.8301L89.587 14.51C89.6978 14.4151 89.8414 14.3671 89.9871 14.3765C90.1328 14.3858 90.2691 14.4517 90.367 14.5601C90.9073 15.1471 91.5695 15.6088 92.3071 15.9128C93.0447 16.2168 93.8399 16.3558 94.637 16.32C96.207 16.32 97.147 15.7901 97.147 14.6901C97.147 13.5901 96.237 13.3601 93.867 12.8301C91.317 12.2701 88.6269 11.3801 88.6269 8.24005C88.6469 5.40005 90.897 3.68006 94.537 3.68006Z" fill="#00234A"></path>
<path d="M119.165 4.04001H121.165C121.311 4.04001 121.451 4.09796 121.554 4.20111C121.657 4.30425 121.715 4.44415 121.715 4.59002V5.46002C121.718 5.51561 121.738 5.56906 121.771 5.61355C121.805 5.65804 121.851 5.69153 121.903 5.70977C121.956 5.72802 122.013 5.73018 122.067 5.71597C122.12 5.70176 122.169 5.67186 122.205 5.63003C122.745 4.99748 123.419 4.49393 124.179 4.15646C124.939 3.81899 125.764 3.65619 126.595 3.68002C130.005 3.68002 131.955 5.90003 131.955 9.75003V18.29C131.955 18.4359 131.898 18.5758 131.794 18.6789C131.691 18.7821 131.551 18.84 131.405 18.84H129.245C129.1 18.84 128.96 18.7821 128.857 18.6789C128.753 18.5758 128.695 18.4359 128.695 18.29V10.29C128.695 7.96 127.575 6.68002 125.475 6.68002C123.075 6.68002 121.805 8.46001 121.805 11.83V18.27C121.805 18.4159 121.747 18.5558 121.644 18.6589C121.541 18.7621 121.401 18.82 121.255 18.82H119.155C119.01 18.82 118.87 18.7621 118.767 18.6589C118.663 18.5558 118.605 18.4159 118.605 18.27V4.57C118.611 4.42588 118.672 4.28959 118.777 4.19046C118.882 4.09133 119.021 4.03729 119.165 4.04001Z" fill="#00234A"></path>
<path d="M60.8685 2.00001C60.8685 1.60445 60.9858 1.21776 61.2056 0.888867C61.4253 0.559968 61.7377 0.303609 62.1031 0.152233C62.4686 0.000858083 62.8707 -0.0387371 63.2587 0.0384333C63.6466 0.115604 64.003 0.306092 64.2827 0.585797C64.5624 0.865502 64.7529 1.22185 64.8301 1.60981C64.9072 1.99778 64.8676 2.39991 64.7162 2.76536C64.5649 3.13081 64.3085 3.44318 63.9796 3.66295C63.6507 3.88271 63.2641 4.00001 62.8685 4.00001C62.6032 4.00982 62.3388 3.96479 62.0917 3.86775C61.8446 3.77071 61.6202 3.62376 61.4325 3.43605C61.2448 3.24834 61.0978 3.02393 61.0007 2.77684C60.9037 2.52975 60.8587 2.26529 60.8685 2.00001Z" fill="#00234A"></path>
<path d="M40.3355 0.169922H42.5855C42.7416 0.170274 42.8956 0.204421 43.0372 0.27002C43.1788 0.335619 43.3044 0.431117 43.4055 0.549927L54.5055 13.2199C54.5411 13.2641 54.5898 13.2959 54.6446 13.3109C54.6993 13.3259 54.7574 13.3232 54.8105 13.3033C54.8637 13.2834 54.9092 13.2472 54.9406 13.1999C54.972 13.1526 54.9877 13.0966 54.9855 13.0399V0.739929C54.9855 0.59406 55.0435 0.454158 55.1466 0.351013C55.2498 0.247868 55.3896 0.189942 55.5355 0.189942H57.8155C57.9614 0.189942 58.1013 0.247868 58.2044 0.351013C58.3076 0.454158 58.3655 0.59406 58.3655 0.739929V18.3099C58.3655 18.4558 58.3076 18.5957 58.2044 18.6989C58.1013 18.802 57.9614 18.8599 57.8155 18.8599H55.5055C55.3495 18.8596 55.1954 18.8254 55.0539 18.7598C54.9123 18.6942 54.7867 18.5987 54.6855 18.4799L43.6355 5.83994C43.5999 5.79574 43.5513 5.7639 43.4965 5.74893C43.4418 5.73397 43.3837 5.73663 43.3305 5.75656C43.2774 5.77649 43.2319 5.81268 43.2004 5.85995C43.169 5.90723 43.1533 5.96321 43.1555 6.01993V18.3099C43.1555 18.4558 43.0976 18.5957 42.9944 18.6989C42.8913 18.802 42.7514 18.8599 42.6055 18.8599H40.3355C40.1897 18.8599 40.0498 18.802 39.9466 18.6989C39.8435 18.5957 39.7855 18.4558 39.7855 18.3099V0.739929C39.7828 0.666037 39.7951 0.59237 39.8215 0.523316C39.8479 0.454261 39.888 0.391222 39.9394 0.338013C39.9907 0.284803 40.0523 0.242505 40.1203 0.213623C40.1884 0.184741 40.2616 0.169873 40.3355 0.169922Z" fill="#00234A"></path>
</svg>
);

export default memo(NielsenLogo)
