import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { grey } from "@mui/material/colors";
import { Permission } from "../../config/roles";
import { TierContext } from "../../context/TierContext";
import { Timestamp } from 'firebase/firestore';


const ChatUsage: React.FC = () => {
  const [tierName, setTierName] = useState<string>('Free Tier');
  const [usage, setUsage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(500);
  const [upgradeTier, setUpgradeTier] = useState<Permission>("limit:chatLimitStandard")
  const { authUser } = useAuth();
  const { checkPermission } = useContext(TierContext);

  useEffect(() => {
    // // Set Usage Limit
    if (authUser?.subscription?.pricing_plan) {
      // check expiration
      if (authUser.subscription.pricing_plan.expiration_date >= Timestamp.fromDate(new Date())){
        switch (authUser.subscription.pricing_plan.tier){
          case 100:
            setTierName('Free Tier')
            setUpgradeTier("limit:chatLimitStandard")
            setLimit(500)
            break;
          case 1000:
            setTierName('Standard Tier')
            setUpgradeTier("limit:chatLimitPremium")
            setLimit(1500)
            break;
          case 2000:
            setTierName('Premium Tier')
            setUpgradeTier("limit:chatLimitUltimate")
            setLimit(5000)
            break;
          case 3000:
            setTierName('Ultimate Tier')
            setUpgradeTier(null)
            setLimit(50000)
            break;
          case 4000:
            setTierName('Developer')
            setUpgradeTier(null)
            setLimit(50000)
            break;
          default:
            setTierName('Free Tier')
            setLimit(500)
        }
      }
    } else {
      setTierName('Free Tier')
      setLimit(500)
    }
    // Set Actual Usage
    if (authUser?.chat_usage){
      // check if last_updated is more than 1 month away from expiration
      if (!false){
        setUsage(authUser.chat_usage.amount || 0)
      } else {
        setUsage(0)
      }
    }
  }, [authUser])

  return (
    <Box
      display='flex'
      flexDirection={'column'}
      justifyContent={'flex-start'}
      sx={{
        width: '100%',
        ml: 2,
        pl: 2,
        mr: 2,
        pr: 2,
        backgroundColor: '#F9F9F9',
        border: '1px solid #E4E4E4',
        borderRadius: '8px'
      }}
    >
      <Typography variant='body1' fontWeight={600} pt={2} style={{ color: 'black' }}>
        Chat Usage
      </Typography>
      <Box
        display='flex'
        flexDirection='row'
        pb={2}
        alignItems={'center'}
      >
        <Box
          flexGrow={1}
          display='flex'
          flexDirection='column'
        >
          <Typography variant='body2' pt={0.5} pb={0.5} style={{ color: 'black' }}>
            {tierName}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.min(usage/limit, 1)*100}
            sx={{
              height: 8,
              backgroundColor: grey[400],
              borderRadius: 8,
              '& .MuiLinearProgress-bar':{
                backgroundColor: (usage/limit) <= 0.5 ? 'green' : ((usage/limit) <= 0.8 ? 'orange' : 'red'),
                borderRadius: 8,
              },
            }}
          />
          <Typography variant='body2' pt={0.5} style={{ color: 'black' }}>
            {`${usage} of ${limit === 50000 ? 'unlimited' : limit} chat responses`}
          </Typography>
        </Box>
        {
          !!upgradeTier &&
          <Button
            variant='contained'
            size='small'
            sx={{
              ml: 1,
            }}
            onClick={() => {
              console.log(upgradeTier)
              checkPermission(upgradeTier)
            }}
          >
            Upgrade
          </Button>
        }
      </Box>

    </Box>
  )
}

export default ChatUsage;
