import { VerifiedRounded } from '@mui/icons-material';
import { Box, Modal, Backdrop, Avatar, Link} from '@mui/material'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '../../svg/InstagramIcon';
import React from 'react'
import Markdown from '../userChat/Markdown';

interface CardChatModalType {
    showIndividualCard: boolean,
    setShowIndividualCard: React.Dispatch<React.SetStateAction<boolean>>;
    profile_data: any,
    response: String,
    id: number,
    response_time: number,
    user_id: String,
    courseList: Array<any>,
    renderLinks: Function,
}

const CardChatModal = (props: CardChatModalType) => {
    const {showIndividualCard=false, setShowIndividualCard, profile_data, response, id, response_time, courseList, renderLinks} = props;
    const { linkedInURL, instagramURL, youtubeURL, userName } = profile_data || {};
    const showChat=false;
    const handleClose = () => {
        setShowIndividualCard(false);
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '10px',
        p: 4,
        maxHeight: '90vh'
      };

  return (

    <Modal
    open={showIndividualCard}
    onClose={handleClose}
    slots={{ backdrop: Backdrop }}
    slotProps={{
        backdrop: {
        timeout: 500,
        },
    }}

    >
        {showChat ?
        <Box sx={style}>
            <iframe src={`${process.env.REACT_APP_URL}${profile_data.userName}/chat`} style={{width: '100%', border: 'none', height: '100%'}}/>
        </Box>
        :
            <Box className='query-response-item-modal' padding={'10px'} bgcolor={id===0? '#efefef50':'#efefef70'} sx={{...style, overflow: 'scroll'}}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} borderBottom={'1px solid #31313123'}>
                <Link
                padding={'5px'}
                display={'flex'}
                flexDirection={'row'}
                gap={'5px'}
                alignItems={'center'}
                href={`${profile_data.userName}/chat`}
                target='_blank'
                rel='noopener noreferrer'
                underline='none'
                color={'black'}
                sx={{
                    transition: 'all 150ms ease',
                    "&:hover": {
                    scale: '1.01',
                    transition: 'all 150ms ease',
                    }
                }}
                >
                <Avatar
                    src={profile_data.profilePicUrl}
                    alt={profile_data.fullName}
                    sx={{
                    width: 35,
                    height: 35,
                    border: '2px solid #fff',
                    fontSize: '1.3rem'
                    }}
                >{profile_data.fullName ? profile_data.fullName[0] : ''}</Avatar>

                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'5px'} >
                        <Box sx={{
                            textDecoration: 'underline #999999',
                            transition: 'all 150ms ease',
                        "&:hover": {
                            textDecoration: 'underline',
                            transition: 'all 150ms ease',
                        }}}>{profile_data.fullName}</Box>
                        {profile_data.verified &&
                        <VerifiedRounded sx={{color:'#00A3FF', fontSize: '17.5px'}}/>
                        }
                    </Box>
                    <Box>
                        <div style={{fontSize: '12px', color: '#636363'}}>{profile_data.occupation ? profile_data.occupation : ''}</div>
                    </Box>
                </Box>
                </Link>
                <Box display={'flex'} alignItems={'flex-end'}>
                {instagramURL && <a
                    id={`href-linkedin-${userName}`}
                    href={renderLinks(instagramURL, 'instagram')}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <InstagramIcon
                    color={'instagramIcon'}
                    size={'25px'}
                    />
                </a>}
                {linkedInURL && <a
                    id={`href-linkedin-${userName}`}
                    href={renderLinks(linkedInURL, 'linkedin')}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <LinkedInIcon
                    color={'linkedinIcon'}
                    sx={{
                        width:'25px'
                    }}
                    />
                </a>}
                {youtubeURL && <a
                    id={`href-youtube-${userName}`}
                    href={renderLinks(youtubeURL, 'youtube')}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <YouTubeIcon
                    color={'youtubeIcon'}
                    sx={{
                        width:'25px'
                    }}
                    />
                </a>}
                </Box>
            </Box>
            <Box padding={'5px'} borderBottom={courseList && '1px solid #31313125'} onClick={() => setShowIndividualCard(true)}>
                <Markdown className={'markdown query-markdown-response-container'}>
                    {response}
                </Markdown>
                <Box fontSize={'11px'} color={'#999999'}>
                    Took {response_time.toFixed(3)} secs to curate...
                </Box>
            </Box>
            {courseList &&
            <Box padding={'5px'} fontSize={'13px'} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={'10px'} alignItems={'center'} className="course-subsection-modal">
                <Box>Courses</Box>
                {courseList?.map((course) => {
                    return (
                        <Box padding={'5px'} bgcolor={'#e4f3ff'} borderRadius={'5px'} minWidth={'max-content'} className="course-subsection-modal-item">
                            <a
                            href={`courses/${course.course_id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            >{course.name}</a>
                        </Box>
                    );
                })}
            </Box>}
        </Box>}
    </Modal>
  )
}

export default CardChatModal
