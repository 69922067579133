import React from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Message } from '@chatscope/chat-ui-kit-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import { useAuth } from '../../hooks/useAuth';

import { MessageDataType } from './messages.types';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import DefaultAvatar from '../../assets/images/default-avatar.png';
import './chat-custom.css';
import { USER_TYPE } from '../../config/const';
import ThumbsUpIcon from '../../svg/ThumbsUpIcon';
import ThumbsDownIcon from '../../svg/ThumbsDownIcon';
import FeedbackMsgIcon from '../../svg/FeedbackMsgIcon';
import { preprocessLaTeX, removeHTML } from '../../utils/helper';
import Tooltip from '@mui/material/Tooltip';
import CustomImageList from './imageList';
import "./messageItem.css";
import Markdown from './Markdown';


export interface MessageItemProps {
  messageDataItem: MessageDataType;
  authUserData: UserFormType | null;
  creatorUserData: UserFormType | null;
  indexKey: string;
  handleLikeDislikeMessage: (documentId: string, value: number) => void;
  showActionMenu: HTMLElement;
  setShowActionMenu: (value: Record<any, any>) => void;
  handleModal: 'edit' | 'feedback' | null;
  setHandleModal: (value: 'edit' | 'feedback' | null) => void;
  formData: Record<any, any> | null;
  setFormData: (value: Record<any, any> | null) => void;
  userName: string;
  handleShareButtonClick: (selectMessage: any) => void;
  showSelectMessages: boolean;
  setShowSelectMessages: (value: boolean) => void;
  resetMessageSelection: () => void;
  isAiTyping?: boolean;
  citations?: { source: string ,source_id: string }[];
  isSelfChat: boolean;
  isCourseChat?: boolean;
  scrollToBottom?: () => void;
  isWidget?: boolean;
}

// const MESSAGE_MENU_OPTIONS = [
//   {
//     label: 'Feedback',
//     value: 'feedback',
//     icon: <FeedbackMsgIcon height='20px' width='20px' />,
//   },
// ];

const MessageItem: React.FC<MessageItemProps> = (props) => {
  const {
    authUserData,
    messageDataItem,
    creatorUserData,
    handleLikeDislikeMessage,
    showActionMenu,
    setHandleModal,
    formData,
    setFormData,
    userName,
    handleShareButtonClick,
    showSelectMessages,
    setShowSelectMessages,
    resetMessageSelection,
    isSelfChat,
    isCourseChat,
    scrollToBottom,
    isWidget = false
  } = props;
  const {
    direction,
    documentId,
    likeDislike,
    message,
    sentTime,
    senderId,
    editedMessage,
    feedback,
    images,
    image_url,
    image_urls,
  } = messageDataItem;
  const {
    userType: authUserType,
    profilePhotoURL: authUserProfilePhoto,
    normalizedUserName: normalizedAuthUserName,
  } = authUserData || {};
  const { isAnonymousUser } = useAuth();
  const { profilePhotoURL: creatorProfilePhoto } = creatorUserData || {};

  // const [selectedCitationIndex, setSelectedCitationIndex] = React.useState(null); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [openTooltip, setOpenTooltip] = React.useState({ index: null, open: false });
  // const tooltipTimeoutRef = React.useRef(null);
  // const [isHovering, setIsHovering] = React.useState(false);

  const handleCitationClick = (index) => {
    setOpenTooltip({ index, open: !openTooltip.open });
  };

  // const handleCloseTooltip = () => {
  //   setOpenTooltip({ index: null, open: false });
  // };

  const uniqueCitations = React.useMemo(() => {
    if (!messageDataItem.citations) return [];
    const seen = new Set();
    return messageDataItem.citations.filter(citation => {
      const duplicate = seen.has(citation.source_id);
      seen.add(citation.source_id);
      return !duplicate;
    });
  }, [messageDataItem.citations]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: (direction === 'incoming' ? 'flex-start' : 'flex-end'),
        overflowY:'auto',
        padding: isWidget && '0px 10px 10px 10px',
        paddingBottom: '10px',
      }}
    >
      {!isWidget && direction === 'incoming' ?
        <Avatar
          src={creatorProfilePhoto}
          alt={creatorUserData.fullName }
          sx={{ m: 1, width: 32, height: 32, fontSize: '1rem'}}
          slotProps={{
            img: {
              loading: 'lazy',
            }
          }}
        >{creatorUserData.fullName  ? creatorUserData.fullName[0] : ''}</Avatar> :
        <></>
      }
      <Message
        model={{
          message: message,
          sentTime: sentTime.toString(),
          sender: senderId,
          direction: direction,
          position: 'single',
        }}
      >
        <Message.CustomContent>
          <Box
            rowGap={0.4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <Markdown className={`markdown ${direction}`}>
              {preprocessLaTeX(editedMessage) || preprocessLaTeX(message)}
            </Markdown>

            <CustomImageList image_url={image_url} documentId={documentId} images={images} image_urls={image_urls} scrollToBottom={scrollToBottom}/>

            <Box>
                {isSelfChat && uniqueCitations.length > 0 && (
                  <div>
                    {uniqueCitations.map((citation, index) => (
                      <Tooltip
                      key={index}
                      open={openTooltip.index === index && openTooltip.open}
                      onClose={() => {
                        setTimeout(() => setOpenTooltip({ index: null, open: false }), 200);
                      }}
                      title={
                        <span style={{ fontSize: '0.8rem', padding: '4px' }}>
                          {isURL(citation.source_id) ? (
                            <a
                              href={citation.source_id}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: 'white', textDecoration: 'underline' }}
                            >
                              {citation.source_id}
                            </a>
                          ) : (
                            citation.source_id
                          )}
                        </span>
                      }
                      placement="top"
                      arrow
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCitationClick(index);
                        }}
                        style={{
                          backgroundColor: openTooltip.index === index && openTooltip.open ? '#666' : '#f0f0f0',
                          color: openTooltip.index === index && openTooltip.open ? '#fff' : 'black',
                          fontSize: '12px',
                          minWidth: '30px',
                          width: '30px',
                          margin: '0 5px',
                          height: '20px',
                          transition: 'all 0.3s',
                        }}
                      >
                        {index + 1}
                      </Button>
                    </Tooltip>
                  ))}
                </div>
              )}
            </Box>
            {normalizedAuthUserName === userName &&
              authUserType === USER_TYPE.CREATOR &&
              direction === 'incoming' &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {(editedMessage ? (
                  <Typography
                    sx={{
                      display: 'flex',
                      // alignSelf: 'flex-end',
                      fontSize: '0.8rem',
                      color: '#A8A8A8',
                      mr: 1,
                    }}
                  >
                    Edited
                  </Typography>
                ) : <></>)}
                {!messageDataItem.isAiTyping && !isCourseChat && (
                <Button
                  id={`button-edit-message-${documentId}`}
                  sx={{
                    display: 'flex',
                    alignSelf: 'flex-end',
                    height: '30px',
                    minWidth: '56px',
                    width: '56px',
                    fontSize: '0.8rem',
                    background: '#D9D9D9',
                    color: '#2C2727',
                    borderRadius: '70px',
                  }}
                  size='small'
                  onClick={() => {
                    setFormData({ documentId, message: removeHTML(editedMessage || message) });
                    setHandleModal('edit');
                  }}
                >
                  Edit
                </Button>
                )}
              </Box>
              }
          </Box>
        </Message.CustomContent>
        {!messageDataItem.isAiTyping && (
        <Message.Footer
          sentTime='just now'
          style={{
            paddingLeft: isWidget && '0.6em',
            columnGap: '6px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // marginTop: '3px',
          }}
        >
          {direction === 'incoming' && (
            <Box
              gap={isWidget ? 0.3 : 0.6}
              sx={{
                display: 'flex',
              }}
            >
              <IconButton
                id={`button-like-message-${documentId}`}
                sx={{
                  padding: '4px',
                  color: likeDislike === 1 ? '#4B9CCD' : '#7D7D7D',
                }}
                onClick={() => handleLikeDislikeMessage(documentId, 1)}
              >
                <ThumbsUpIcon
                  height='20px'
                  width='20px'
                  fill={likeDislike === 1 ? '#4B9CCD' : 'none'}
                />
              </IconButton>
              <IconButton
                id={`button-dislike-message-${documentId}`}
                sx={{
                  padding: '4px',
                  color: likeDislike === 2 ? '#4B9CCD' : '#7D7D7D',
                }}
                onClick={() => handleLikeDislikeMessage(documentId, 2)}
              >
                <ThumbsDownIcon
                  height='20px'
                  width='20px'
                  fill={likeDislike === 2 ? '#4B9CCD' : 'none'}
                />
              </IconButton>
              <IconButton
                id={`button-toggle-menu-message-${documentId}`}
                sx={{
                  padding: '4px',
                }}
                aria-controls={
                  Boolean(showActionMenu && formData?.documentId === documentId)
                    ? 'long-menu'
                    : undefined
                }
                aria-expanded={
                  Boolean(showActionMenu && formData?.documentId === documentId)
                    ? 'true'
                    : undefined
                }
                onClick={(event) => {
                  setFormData({ documentId, feedback });
                  setHandleModal('feedback');

                }}
                aria-haspopup='true'
              >
                <FeedbackMsgIcon
                  color={
                    Boolean(showActionMenu && formData?.documentId === documentId)
                      ? 'primary'
                      : 'secondary'
                  }
                  height='20px'
                  width='20px'
                />
              </IconButton>
              {!isWidget &&
              <IconButton
                id={`button-share-message-${documentId}`}
                sx={{
                  padding: '4px',
                  color: '#acacac',

                }}
                onClick={() => {
                  if (showSelectMessages) {
                    resetMessageSelection();
                    return;
                  }
                  setShowSelectMessages(!showSelectMessages);
                  handleShareButtonClick(messageDataItem);
                }}
              >
                <ShareOutlinedIcon

                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>}
            </Box>
          )}
          {!isWidget &&
          <Typography
            sx={{
              backgroundColor: 'transparent',
              display: 'flex',
              fontSize: '0.8rem',
              margin: '0.1em 0.2em 0 0.2em',
              color: '#9AACBD',
            }}
          >
            {moment(
              typeof sentTime === 'object' ? sentTime?.toDate?.() : sentTime
            ).format('hh:mm a')}
          </Typography>}
        </Message.Footer>
        )}
      </Message>
      {!isWidget ? direction === 'incoming' ?
        <></> :
        <Avatar
          src={isAnonymousUser ? DefaultAvatar : authUserProfilePhoto}
          alt={authUserData?.fullName }
          sx={{ m: 1, width: 32, height: 32, fontSize: '1rem'}}
        >{authUserData?.fullName ? authUserData?.fullName[0] : ''}</Avatar>
      :<></>}
    </Box>
  );
};

export default React.memo(MessageItem);

const isURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};
