import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Box, Button, Card, CardActions, CardContent, Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import StripeCheckoutForm from '../StripeCheckoutForm';
import { useEffect, useRef, useState } from "react";
import { CONTACT_US_MAIL, PRICES_A, PRICES_B, TIER_LEVEL_CONVERSION } from "../../config/const";
import { ACADEMIC_FEATURES, ENTERPRISE_FEATURES, FREE_FEATURES, PREMIUM_FEATURES, STANDARD_FEATURES, ULTIMATE_FEATURES, UPGRADE_FEATURE, UPGRADE_TEXT } from "./priceDescriptions";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Timestamp } from "firebase/firestore";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '../../svg/CloseIcon';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

interface TiersCardProps {
  upgradeFeature?: UPGRADE_FEATURE;
}

const TiersCard: React.FC<TiersCardProps> = ({upgradeFeature}) => {
  const [ variant, ] = useLocalStorage('variant', (Math.random() < 0.5 ? 'a' : 'b'))
  const [ variantPrice, setVariantPrice ] = useState(PRICES_B)
  const [ stripeItem, setStripeItem ] = useState(null);
  const [ stripeOpen, setStripeOpen ] = useState(false);
  const [ trialPeriod, setTrialPeriod ] = useState(0);
  const [ featModalData, setFeatModalData ] = useState(null);
  const [ featModalOpen, setFeatModalOpen ] = useState<boolean>(false);
  const [ currentTier, setCurrentTier ] = useState<string>(null);

  const recommendedTier = useRef<string>('premium')

  const navigate = useNavigate();
  const { authUser } = useAuth();
  const eventTracker = useAnalyticsEventTracker();
  const location = useLocation();

  useEffect(() => {
    if (variant === 'a') {
      setVariantPrice(PRICES_A)
    } else {
      setVariantPrice(PRICES_B)
    }
    if (variant) {
      eventTracker('tier-card loaded', {
        variant: variant,
      })
    }
  }, [variant]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // reset to start
    recommendedTier.current = 'premium';
    setCurrentTier(null)

    if (authUser?.subscription?.pricing_plan) {
      let currentTimeStamp = Timestamp.fromDate(new Date());
      let current_plan = authUser.subscription.pricing_plan;
      if (current_plan.expiration_date > currentTimeStamp) {
        if (current_plan.tier === 100 ) {
          recommendedTier.current = 'premium';
          setCurrentTier('free')
        } else if (current_plan.tier === 1000 ) {
          recommendedTier.current = 'premium';
          setCurrentTier('standard')
        } else if (current_plan.tier === 2000) {
          recommendedTier.current = 'ultimate';
          setCurrentTier('premium')
        } else if (current_plan.tier === 3000) {
          recommendedTier.current = null;
          setCurrentTier('ultimate')
        } else if (current_plan.tier === 3100) {
          recommendedTier.current = null;
          setCurrentTier('enterprise')
        } else if (current_plan.tier === 3200) {
          recommendedTier.current = null;
          setCurrentTier('academic')
        }
      }
    }
  }, [authUser])

  const handlePurchaseButton = (tier: string) => {
    if (tier !== currentTier) {
      setStripeItem(`tier_${tier}_${variant}`)
      // if (!currentTier) {
      //   if (tier === 'free') {
      //     setTrialPeriod(0)
      //   } else if (tier === 'standard') {
      //     setTrialPeriod(7)
      //   } else {
      //     setTrialPeriod(15)
      //   }
      // } else {
      //   setTrialPeriod(0)
      // }
      eventTracker('tier-card start-purchase', {
        variant: variant,
        tier: tier,
        currentTier: currentTier,
        // trialPeriod: !currentTier
      })
      setStripeOpen(true)
    }
  }

  const openFeatModal = (title: string, description) => {
    setFeatModalData({
      'title': title,
      'description': description,
    })
    eventTracker('tier-card open-feat-description', {
      variant: variant,
      title: title,
    })
    setFeatModalOpen(true)
  }

  const TierHeader = ({tier}) => {
    return (
      (tier === recommendedTier.current) ?
        <Box
          height={40}
          display='flex'
          justifyContent='center'
          alignItems={'center'}
          sx={{
            backgroundColor: '#3EACEF',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            mb: -0.5,
          }}
        >
          <Typography component='body' color='white' textAlign='center' height={'fit-content'}>
            Recommended For You
          </Typography>
        </Box> :
      (tier === currentTier) ?
        <Box
          height={40}
          display='flex'
          justifyContent='center'
          alignItems={'center'}
          sx={{
            backgroundColor: 'gray',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            mb: -0.5,
          }}
        >
          <Typography component='body' color='white' textAlign='center' height={'fit-content'}>
            Your Current Plan
          </Typography>
        </Box> :
        (tier === 'enterprise' || tier === 'academic') ?
        <Box height={{xs: 0}} sx={{mb: -0.5}}> </Box> :
        <Box height={{xs: 0, md: 40}} sx={{mb: -0.5}}> </Box>
    )
  }

  const UpgradeTierCard = () => {
    let { title, description, bold, tier } = UPGRADE_TEXT[upgradeFeature]
    let upgradeFeatures: {[key: string]: any[]} = {};

    let boldArr = [];
    if (typeof bold === 'object') boldArr = bold;
    else boldArr = [bold];

    switch (tier){
      case ('Standard'):
        upgradeFeatures = STANDARD_FEATURES;
        break;
      case ('Premium'):
        upgradeFeatures = PREMIUM_FEATURES;
        break;
      case ('Ultimate'):
        upgradeFeatures = ULTIMATE_FEATURES;
        break;
    }

    return (
      <Grid container
        spacing={{xs: 0, md: 4 }}
        display='flex'
        flexDirection={{xs: 'column-reverse', md: 'row'}}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={12} md={6} >
          <Card
            sx={{
              height: {xs: 'auto', md: 'calc(100% - 40px)'},
              boxShadow: 'none',
              backgroundColor: '#F4FBFF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent
              sx={{
                m: {xs: 1, md: 2},
              }}
            >
              <Box display='flex' alignItems={'flex-end'} >
                <Typography component='h6' fontWeight={600} fontSize={18}>
                  {tier}
                </Typography>
              </Box>

              <Box display='flex' alignItems={'flex-end'} mb={1}>
                <Typography component='h6' fontWeight={600} fontSize={30}>
                  ${`${variantPrice[tier.toLowerCase()]}`}
                </Typography>
                <Typography component='h6' fontWeight={600} fontSize={14} pl={0.5} pb={0.75}>
                  USD/Month
                </Typography>
              </Box>

              {Object.entries(upgradeFeatures).map(([key, value], ind) => {
                return (
                  <Box
                    key={`upgrade-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1,
                      }}
                      fontWeight={boldArr.includes(key) ? 600 : 400}
                    >
                      {`${key}`}
                    </Typography>
                    {
                      !!value.length &&
                        <InfoIcon
                          style={{
                            fill: 'gray',
                            marginLeft: 'auto',
                          }}
                        />
                    }
                  </Box>
                )
              })}
            </CardContent>

            {/* <CardActions>
              <Typography component='body' textAlign='center' fontSize={14} fontStyle='italic'>
                *coming soon
              </Typography>
            </CardActions> */}
          </Card>
        </Grid>

        <Grid item xs={12} md={6} >
          <Box display='flex' alignItems={'flex-end'} mb={1} mt={{xs: 2, md: 0}}>
            <Typography component='h6' fontWeight={600} fontSize={26} lineHeight={{xs: 1.25, md: 1.5}}>
              {title}
            </Typography>
          </Box>

          <Box display='flex' alignItems={'flex-end'} mb={1}>
            <Typography component='body' fontWeight={400} fontSize={14} pl={0.5} pb={0.75}>
              {description}
            </Typography>
          </Box>

          <Button
            variant='contained'
            size='small'
            color='mainBlue'
            onClick={() => handlePurchaseButton(tier.toLowerCase())}
            sx={{
              mb: 2,
              pl: 3,
              pr: 3,
              fontSize: 14,
              borderRadius: 40,
              '&.MuiButton-outlined': {
                borderColor: '#3EACEF',
                color: '#3EACEF',
              },
            }}
          >
            Upgrade Now
          </Button>
        </Grid>

        <StripeCheckoutForm
          item={stripeItem}
          isOpen={stripeOpen}
          setIsOpen={(input:boolean) => {
            if (!input) {
              setStripeItem(null)
              setTrialPeriod(null)
            }
            setStripeOpen(input)
          }}
          trialPeriod={trialPeriod}
          redirectURL={location.pathname}
        />
      </Grid>
    )
  }

  return (
    (upgradeFeature && upgradeFeature !== 'justOpen') ? <UpgradeTierCard /> :
    <Box
      display='flex'
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      height={'100%'}
    >
      <Typography component='h1' fontWeight={600} fontSize={24} mt={2} mb={3}>
        {"Choose a plan to scale your knowledge"}
      </Typography>

      <Grid container spacing={2} display='flex' alignItems={'stetch'} mb={2}>
        <Grid item xs={12} md={3} >
          <TierHeader tier='free' />
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 0px 5px #00000038',
              height: {xs: 'auto', md: 'calc(100% - 40px)'},
              backgroundColor: recommendedTier.current === 'free' ? '#f7f7f7' : 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent>
              <Typography component='h6' fontWeight={600} pt={1} pb={0.5}>
                Beginner
              </Typography>
              <Typography component='body' fontSize={14} maxHeight={80}
                height={{xs: 'fit-content', md: 80, laptop: 64}}
              >
                The basics you need to get started and see the value of an AI persona.
              </Typography>

              <Box display='flex' alignItems={'flex-end'} pt={{xs: 0.5, md: 3}} mb={1}>
                <Typography component='h6' fontWeight={600} fontSize={30}>
                  Free
                </Typography>
              </Box>

              <Button
                variant={recommendedTier.current === 'free' ? 'contained' : 'outlined'}
                size='small'
                color='mainBlue'
                fullWidth
                onClick={() => handlePurchaseButton('free')}
                sx={{
                  mb: 2,
                  fontSize: 14,
                  borderRadius: 40,
                  '&.MuiButton-outlined': {
                    borderColor: '#3EACEF',
                    color: '#3EACEF',
                  },
                }}
              >
                {
                  currentTier === 'free' ?
                    'Currently Enrolled' :
                    authUser.userType === 'fan' ? 'Create My AI Persona' :
                    'Select Plan'
                }
              </Button>

              {Object.entries(FREE_FEATURES).map(([key,value], ind) => {
                return (
                  <Box
                    key={`free-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1,
                      }}
                    >
                      {`${key}`}
                    </Typography>
                    {
                      !!value.length &&
                        <InfoIcon
                          style={{
                            fill: 'gray',
                            marginLeft: 'auto',
                          }}
                        />
                    }
                  </Box>
                )
              })}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3} >
          <TierHeader tier='standard' />
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 0px 5px #00000038',
              height: {xs: 'auto', md: 'calc(100% - 40px)'},
              backgroundColor: recommendedTier.current === 'standard' ? '#f7f7f7' : 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent>
              <Typography component='h6' fontWeight={600} pt={1} pb={0.5}>
                Standard
              </Typography>
              <Typography component='body' fontSize={14} maxHeight={80}
                height={{xs: 'fit-content', md: 80, laptop: 64}}
              >
                Train, analyze, and perfect your AI Persona and performance.
              </Typography>

              <Box display='flex' alignItems={'flex-end'} pt={{xs: 0.5, md: 3}} mb={1}>
                <Typography component='h6' fontWeight={600} fontSize={30}>
                  ${`${variantPrice['standard']}`}
                </Typography>
                <Typography component='h6' fontWeight={600} fontSize={14} pl={0.5} pb={0.75}>
                  USD/Month
                </Typography>
              </Box>

              <Button
                variant={recommendedTier.current === 'standard' ? 'contained' : 'outlined'}
                size='small'
                color='mainBlue'
                fullWidth
                onClick={() => handlePurchaseButton('standard')}
                sx={{
                  mb: 2,
                  fontSize: 14,
                  borderRadius: 40,
                  '&.MuiButton-outlined': {
                    borderColor: '#3EACEF',
                    color: '#3EACEF',
                  },
                }}
              >
                {
                  currentTier === 'standard' ?
                    'Currently Enrolled' :
                    currentTier ? (
                      TIER_LEVEL_CONVERSION[currentTier] < 1000 ?
                        'Upgrade to Standard' :
                        'Select Plan'
                      ) : 'Select Plan'
                    // 'Start 7 Day Free Trial'
                }
              </Button>

              {Object.entries(STANDARD_FEATURES).map(([key,value], ind) => {
                return (
                  <Box
                    key={`std-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1,
                      }}
                    >
                      {`${key}`}
                    </Typography>
                    {
                      !!value.length &&
                        <InfoIcon
                          style={{
                            fill: 'gray',
                            marginLeft: 'auto',
                          }}
                        />
                    }
                  </Box>
                )
              })}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <TierHeader tier='premium' />
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 0px 5px #00000038',
              height: {xs: 'auto', md: 'calc(100% - 40px)'},
              backgroundColor: recommendedTier.current === 'premium' ? '#f7f7f7' : 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent>
              <Typography component='h6' fontWeight={600} pt={1} pb={0.5}>
                Premium
              </Typography>
              <Typography component='body' fontSize={14} maxHeight={80}
                height={{xs: 'fit-content', md: 80, laptop: 64}}
              >
                Everything you need to scale and monetize your knowledge and influence.
              </Typography>

              <Box display='flex' alignItems={'flex-end'} pt={{xs: 0.5, md: 3}} mb={1}>
                <Typography component='h6' fontWeight={600} fontSize={30}>
                  ${`${variantPrice['premium']}`}
                </Typography>
                <Typography component='h6' fontWeight={600} fontSize={14} pl={0.5} pb={0.75}>
                  USD/Month
                </Typography>
              </Box>

              <Button
                variant={recommendedTier.current === 'premium' ? 'contained' : 'outlined'}
                size='small'
                color='mainBlue'
                fullWidth
                onClick={() => handlePurchaseButton('premium')}
                sx={{
                  mb: 2,
                  fontSize: 14,
                  borderRadius: 40,
                  '&.MuiButton-outlined': {
                    borderColor: '#3EACEF',
                    color: '#3EACEF',
                  },
                }}
              >
              {
                currentTier === 'premium' ?
                  'Currently Enrolled' :
                  currentTier ? (
                    TIER_LEVEL_CONVERSION[currentTier] < 2000 ?
                      'Upgrade to Premium' :
                      'Select Plan'
                    ) : 'Select Plan'
                  // 'Start 15 Day Free Trial'
              }
              </Button>

              {Object.entries(PREMIUM_FEATURES).map(([key,value], ind) => {
                return (
                  <Box
                    key={`premium-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1
                      }}
                    >
                      {`${key}`}
                    </Typography>
                    {
                      !!value.length &&
                        <InfoIcon
                          style={{
                            fill: 'gray',
                            marginLeft: 'auto',
                          }}
                        />
                    }
                  </Box>
                )
              })}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <TierHeader tier='ultimate' />
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 0px 5px #00000038',
              height: {xs: 'auto', md: 'calc(100% - 40px)'},
              backgroundColor: recommendedTier.current === 'ultimate' ? '#f7f7f7' : 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent>
              <Typography component='h6' fontWeight={600} pt={1} pb={0.5}>
                Ultimate
              </Typography>
              <Typography component='body' fontSize={14} maxHeight={80}
                height={{xs: 'fit-content', md: 80, laptop: 64}}
              >
                Scale, monetize, and take your AI Persona with you.
              </Typography>

              <Box display='flex' alignItems={'flex-end'} pt={{xs: 0.5, md: 3}} mb={1}>
                <Typography component='h6' fontWeight={600} fontSize={30}>
                  ${`${variantPrice['ultimate']}`}
                </Typography>
                <Typography component='h6' fontWeight={600} fontSize={14} pl={0.5} pb={0.75}>
                  USD/Month
                </Typography>
              </Box>

              <Button
                variant={recommendedTier.current === 'ultimate' ? 'contained' : 'outlined'}
                size='small'
                color='mainBlue'
                fullWidth
                onClick={() => handlePurchaseButton('ultimate')}
                sx={{
                  mb: 2,
                  fontSize: 14,
                  borderRadius: 40,
                  '&.MuiButton-outlined': {
                    borderColor: '#3EACEF',
                    color: '#3EACEF',
                  },
                }}
              >
              {
                currentTier === 'ultimate' ?
                  'Currently Enrolled' :
                  currentTier ? (
                    TIER_LEVEL_CONVERSION[currentTier] < 3000 ?
                      'Upgrade to Ultimate' :
                      'Select Plan'
                    ) : 'Select Plan'
                  // 'Start 15 Day Free Trial'
              }
              </Button>

              {Object.entries(ULTIMATE_FEATURES).map(([key,value], ind) => {
                return (
                  <Box
                    key={`ultimate-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1,
                      }}
                    >
                      {`${key}`}
                    </Typography>
                    {
                      !!value.length &&
                        <InfoIcon
                          style={{
                            fill: 'gray',
                            marginLeft: 'auto',
                          }}
                        />
                    }
                  </Box>
                )
              })}
            </CardContent>

            <CardActions>
              <Typography component='body' textAlign='center' fontSize={14} fontStyle='italic'>
                *coming soon
              </Typography>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <TierHeader tier='enterprise' />
          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 0px 5px #00000038',
              height: '100%',
              // height: 'calc(50% - 28px)',
              // mb: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent>
              <Typography component='h6' fontWeight={600} pt={1} pb={0.5}>
                Enterprise
              </Typography>
              <Typography component='body' fontSize={14} height={'fit-content'}>
                Keep your expertise inside the company, even when Employees leave. Available to everyone.
              </Typography>

              <Button
                variant='outlined'
                size='small'
                color='mainBlue'
                fullWidth
                href={`mailto:${CONTACT_US_MAIL}&subject=${encodeURIComponent("Inquiry regarding Soopra Enterprise Tier")}`}
                onClick={() => {
                  eventTracker('tier-card enterprise', {
                    variant: variant,
                    tier: 'academic',
                  })
                }}
                sx={{
                  mt: 2,
                  mb: 2,
                  fontSize: 14,
                  borderRadius: 40,
                  '&.MuiButton-outlined': {
                    borderColor: '#3EACEF',
                    color: '#3EACEF',
                  },
                }}
              >
                Let's Talk
              </Button>

              {Object.entries(ENTERPRISE_FEATURES).map(([key,value], ind) => {
                return (
                  <Box
                    key={`enterprise-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1,
                        textDecoration: !!value.length ? 'underline' : 'none'
                      }}
                    >
                      {`${key}`}
                    </Typography>
                  </Box>
                )
              })}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <TierHeader tier='academic' />

          <Card
            variant="outlined"
            sx={{
              boxShadow: '0px 0px 5px #00000038',
              // '&:last-child': {
              //   mb: 4,
              // }
              height: '100%',
            }}
          >
            <CardContent>
              <Typography component='h6' fontWeight={600} pt={1} pb={0.5}>
                Academic Institution
              </Typography>
              <Typography component='body' fontSize={14} height={'fit-content'}>
                Enhance education by making Professors available to students 24/7
              </Typography>

              <Button
                variant='outlined'
                size='small'
                color='mainBlue'
                fullWidth
                href={`mailto:${CONTACT_US_MAIL}&subject=${encodeURIComponent("Inquiry regarding Soopra Academic Tier")}`}
                onClick={() => {
                  eventTracker('tier-card academic', {
                    variant: variant,
                    tier: 'academic',
                  })
                }}
                sx={{
                  mt: 2,
                  mb: 2,
                  fontSize: 14,
                  borderRadius: 40,
                  '&.MuiButton-outlined': {
                    borderColor: '#3EACEF',
                    color: '#3EACEF',
                  },
                }}
              >
                Let's Talk
              </Button>

              {Object.entries(ACADEMIC_FEATURES).map(([key,value], ind) => {
                return (
                  <Box
                    key={`academic-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1,
                        textDecoration: !!value.length ? 'underline' : 'none'
                      }}
                    >
                      {`${key}`}
                    </Typography>
                  </Box>
                )
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <StripeCheckoutForm
        item={stripeItem}
        isOpen={stripeOpen}
        setIsOpen={(input:boolean) => {
          if (!input) {
            setStripeItem(null)
            setTrialPeriod(null)
          }
          setStripeOpen(input)
        }}
        trialPeriod={trialPeriod}
        redirectURL={location.pathname}
      />

      <Dialog
        open={featModalOpen}
        onClose={() => setFeatModalOpen(false)}
        fullWidth
        maxWidth='md'
      >
        <DialogContent
          sx={{
            '&:last-child': {
              pb: 4,
            }
          }}
        >
          <IconButton
            id='button-close-tiers-feat-info'
            onClick={() => setFeatModalOpen(false)}
            color='primary'
            sx={{
              position: 'absolute',
              alignSelf: 'flex-end',
              minWidth: '36px',
              minHeight: '36px',
              height: '36px',
              width: '36px',
              top: '16px',
              right: '16px',
            }}
          >
            <CloseIcon height='30px' width='30px' />
          </IconButton>
          <Typography component='h6' fontWeight={700} pt={1} pb={1} fontSize={20}>
            {featModalData?.title}
          </Typography>
          {
            (featModalData?.description || []).map((entry, ind) => {
              if (typeof entry === 'object') {
                return (
                  <Typography
                    key={`${featModalData?.title}-${ind}`}
                    component='body'
                    fontSize={14}
                    sx={{
                      mt: 1,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate(entry.link)
                    }}
                  >
                    {entry.description}
                  </Typography>
                )
              } else {
                return (
                  <Typography
                    key={`${featModalData?.title}-${ind}`}
                    component='body'
                    fontSize={14}
                    sx={{
                      mt: 1,
                    }}
                  >
                    {entry}
                  </Typography>
                )
              }
            })
          }
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default TiersCard;
