// import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useState, useRef, useEffect, Suspense, useContext, useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Box, Button, Typography } from "@mui/material";
import { getFormattedDate, wrapPromise } from "../../utils/helper";
import { getSubscriptions } from "../../utils/api";
import { Link } from "react-router-dom";
import { TierContext } from "../../context/TierContext";
import CreatorSubscriptionData from "./CreatorSubscriptionData";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

export interface SubscriptionType {
  amount: number;
  canceled_at: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  id: string;
  interval: string;
  product_name: string;
  creator_name?: string;
  creator_pic?: string;
  creator_link?: string;
}

const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';

const SubscriptionData: React.FC = () => {
  const [ soopraSub, setSoopraSub ] = useState<SubscriptionType>(null);
  const [ creatorSubs, setCreatorsSubs] = useState<SubscriptionType[]>([]);
  const [ showCancelModal, setShowCancelModal ] = useState<boolean>(false);
  const [ cancelSubData, setCancelSubData ] = useState<SubscriptionType>(null);

  const loadedData = useRef(wrapPromise(Promise.resolve()));
  const { authUser } = useAuth();
  const { checkPermission } = useContext(TierContext)

  useEffect(() => {
    loadedData.current = wrapPromise(
      getSubscriptions(authUser.uid)
        .then(res => {
          if (res.ok) {
            return res.json()
          } else {
            throw res
          }
        }).then(data => {
          let filtered_data = {'SoopraSubscription': null, 'CreatorSubscriptions': []};
          for (let entry of data.subscriptions) {
            if (!entry.canceled_at){
              if (entry.creator_name){
                filtered_data.CreatorSubscriptions.push(entry)
              } else {
                filtered_data.SoopraSubscription ||= entry;
              }
            } else {
              // To-do: show cancelled subscriptions
              // users should be able to easily resubscribe
            }
          }
          if (enableDevTools){ console.log(filtered_data) }
          setSoopraSub(filtered_data.SoopraSubscription)
          setCreatorsSubs(filtered_data.CreatorSubscriptions)
        })
    )
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cancelSubData) {
      setShowCancelModal(true)
    }
  }, [cancelSubData])

  useEffect(() => {
    if (!showCancelModal) {
      setCancelSubData(null)
    }
  }, [showCancelModal])

  let SoopraSubscription = useMemo(() => {
    return (
      <Box
        sx={{
          border: '1px solid rgba(0,0,0,0.12)',
          borderRadius: '8px',
          padding: '16px 24px',
          width: '100%',
          maxWidth: '1000px',
          display: 'flex',
          flexDirection: 'column',
          mt: 2
        }}
      >
        <Box display='flex' justifyContent='space-between'
          sx={{
            width: '100%',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            pt: 1,
            pb: 1,
          }}
        >
          <Box display='flex' flexDirection='column' p={0}>
            <Typography
              component='h6' variant='h6' fontWeight={600}
              sx={{
                fontSize: '18px', // Change the font family
                color: 'black',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                height: '1.5em',
              }}
            >
              My Soopra Subscription
            </Typography>
            <Typography variant='body1' color='black' fontSize={'0.9rem'}>
              My AI Persona
            </Typography>
          </Box>
          <Button variant='outlined' size='small'
            onClick={()=> {
              checkPermission('justOpen:justOpen')
            }}
          >
            Edit
          </Button>
        </Box>
        {
          soopraSub ?
          <Box display='flex' flexDirection='column' mt={1}>
            <Typography variant='body1' color='black' fontWeight={600}>
              {`${soopraSub.product_name} - $${Math.round(soopraSub.amount)/100}/${soopraSub.interval}`}
            </Typography>
            <Typography variant='body1' color='black' fontSize={'0.9rem'}>
              {`Next Payment - ${getFormattedDate(new Date(soopraSub.current_period_end*1000))}`}
            </Typography>
          </Box> :
          <Box display='flex' flexDirection='column' mt={1}>
            <Typography variant='body1' color='black'>
              Free Plan - Free
            </Typography>
            <Typography>
              {' '}
            </Typography>
          </Box>
        }
      </Box>
    )
  }, [soopraSub]) //eslint-disable-line react-hooks/exhaustive-deps

  let CreatorSubscriptions = useMemo(() => {
    return (
      <Box
        sx={{
          border: '1px solid rgba(0,0,0,0.12)',
          borderRadius: '8px',
          padding: '16px 24px',
          width: '100%',
          maxWidth: '1000px',
          display: 'flex',
          flexDirection: 'column',
          mt: 2
        }}
      >
        <Box display='flex' justifyContent='space-between'
          sx={{
            width: '100%',
            pt: 1,
            pb: 1,
            mb: 1,
          }}
        >
          <Box display='flex' flexDirection='column' p={0}>
            <Typography
              component='h6' variant='h6' fontWeight={600}
              sx={{
                fontSize: '18px', // Change the font family
                color: 'black',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                height: '1.5em',
              }}
            >
              Expert Subscriptions
            </Typography>
            <Typography variant='body1' color='black' fontSize={'0.9rem'}>
              Expert AI Personas I am subscribed to
            </Typography>
          </Box>
        </Box>
        {
          creatorSubs.length ?
          creatorSubs.map((entry:SubscriptionType, idx: number) => {
            return (
              <Link key={`creator-${idx}`} to={entry.creator_link}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  sx={{
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                    pt: 2,
                    pb: 2,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)'
                    }
                  }}
                >
                  <CreatorSubscriptionData creatorData={entry}/>
                  <Button variant='outlined' size='small'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCancelSubData(entry)
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              </Link>
            )
          }) :
          <Box display='flex' flexDirection='column' pt={1}
            sx={{
              borderTop: '1px solid rgba(0,0,0,0.12)',
            }}
          >
            <Typography variant='body1' color='black'>
              You have no subscriptions.
            </Typography>
            <Link to='/creators'>
              <Button variant='contained' size='small' sx={{mt: 1}}>
                Discover Experts
              </Button>
            </Link>
          </Box>
        }
      </Box>
    )
  }, [creatorSubs])

  // let PaymentMethod = () => {
  //   return (
  //     <Box
  //       sx={{
  //         border: '1px solid rgba(0,0,0,0.12)',
  //         borderRadius: '8px',
  //         padding: '16px 24px',
  //         width: '100%',
  //         maxWidth: '1000px',
  //         display: 'flex',
  //         flexDirection: 'column',
  //         mt: 2
  //       }}
  //     >
  //       <Typography
  //         component='h6' variant='h6' fontWeight={600}
  //         sx={{
  //           fontSize: '18px', // Change the font family
  //           color: 'black',
  //           display: '-webkit-box',
  //           overflow: 'hidden',
  //           WebkitLineClamp: 1,
  //           WebkitBoxOrient: 'vertical',
  //           height: '1.5em',
  //         }}
  //       >
  //         Payment Method
  //       </Typography>
  //       <Box display='flex' width='100%' justifyContent={'flex-start'}
  //         sx={{
  //           pt: 1,
  //           mt: 1,
  //           borderTop: '1px solid rgba(0,0,0,0.12)',
  //         }}
  //       >
  //         <CreditCardIcon />
  //         <Box sx={{ml: 1,}}>
  //           <Typography variant='body1' color='black' fontWeight={600}>
  //             Payment method
  //           </Typography>
  //           <Typography variant='body1' color='rgba(0,0,0,0.6)' fontSize='0.9rem'>
  //             Credit Card
  //           </Typography>
  //         </Box>
  //       </Box>
  //     </Box>
  //   )
  // }

  return (
    <Suspense fallback ={<></>}>
      { SoopraSubscription }
      { CreatorSubscriptions }
      {/* <PaymentMethod /> */}
      <CancelSubscriptionModal subscription={cancelSubData} setSubscription={setCancelSubData} />
    </Suspense>
  )
}

export default SubscriptionData
