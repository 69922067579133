import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import NielsenLogo from './NielsenLogo';
import './Nielsen.css';
import { useSearchParams } from "react-router-dom";

const Nielsen: React.FC = () => {
  const [ tableData, setTableData ] = useState(null);
  const [ totalImpressions, setTotalImpressions ] = useState(null);
  const [ percentData, setPercentData ] = useState(null);
  const [ numPublishers, setNumPublishers ] = useState(null);
  const [ ROAS, setROAS ] = useState(null);

  let [searchParams, ] = useSearchParams();
  let [ queryNum, ] = useState(searchParams.get("page") || "")

  useEffect(() => {
    fetchNielsenData()
      .then(data => {
        // console.log(data)
        // setTableData(data)
        let totals = {
          'LINEAR': [0,0,0,0],
          'CTV': [0,0,0,0],
          'COMPUTER': [0,0,0,0],
          'MOBILE': [0,0,0,0],
        }
        let numInvested = {
          'LINEAR': [0,0],
          'CTV': [0,0],
          'COMPUTER': [0,0],
          'MOBILE': [0,0],
        }
        let impressions = [0, 0];
        for (let row of data) {
          // Count $ per media type
          // columns are:
          // 0: Actual Campaign $ Invested
          // 1: Reoptimized Campaign $ Invested
          // 2: Actual Campaign $ Returned
          // 3: Reoptimized Campaign $ Returned
          let data = totals[row[0].replace(/\s/g, "")];
          data[0] += parseFloat(row[6]);
          data[1] += parseFloat(row[7]);
          data[2] += parseFloat(row[8]);
          data[3] += parseFloat(row[9]);

          // Count number of platforms invested per media type
          let data2 = numInvested[row[0].replace(/\s/g, "")];
          if (parseFloat(row[6])) {
            data2[0] += 1;
          }
          if (parseFloat(row[7])) {
            data2[1] += 1;
          }

          // sum up impressions
          impressions[0] += parseInt(row[4]);
          impressions[1] += parseInt(row[5]);
        }
        let columnSums = [0,0,0,0];
        for (let row of Object.values(totals)) {
          for (let i = 0; i < 4; i++) {
            columnSums[i] += row[i];
          }
        }
        // console.log('sums', columnSums)

        let roas = [columnSums[2]/columnSums[0], columnSums[3]/columnSums[1]]

        let percentages = {}
        for (let key of Object.keys(totals)) {
          percentages[key] = totals[key].slice().map((val, ind) => val/columnSums[ind]*100)
        }
        let numInvestedTotals = [0,0];
        for (let val of Object.values(numInvested)){
          numInvestedTotals[0] += val[0]
          numInvestedTotals[1] += val[1]
        }
        numInvested["TOTAL"] = numInvestedTotals;

        // add percentage improvement
        for (let key of Object.keys(numInvested)) {
          let data = numInvested[key];
          if (data[0]) {
            let perc = Math.floor((data[1]-data[0])/data[0]*100)
            data.push(perc)
          } else {
            data.push('Infinite')
          }
        }

        setTableData(totals)
        setPercentData(percentages)
        setNumPublishers(numInvested)
        setROAS(roas)
        setTotalImpressions(impressions)

        // console.log(totals, percentages, numInvested, roas)
      })
  }, [])

  return (
    <Box width='100%' height='100%'
      display='flex'
      flexDirection='column'
      position='absolute'
      top={0}
      bottom={0}
      left={0}
      right={0}
    >
      <Box
        width='100%'
        display='flex'
        p={3}
        pt={2}
        pb={2}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
        position='sticky'
        top={0}
        left={0}
        right={0}
        sx={{backgroundColor: 'white', zIndex: 25}}
      >
        <NielsenLogo />
        <Typography variant='body2' color='black'>
          {`(Target: Persons 18+)`}
        </Typography>
      </Box>
      {
        !!tableData &&
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{
            backgroundColor: '#FAFAFA',
          }}
        >
          <Typography
            variant='h4'
            fontWeight={600}
            sx={{
              pt: 2,
              pb: 2,
            }}
          >
            Brand: Blooies
          </Typography>

          <Grid container>
            <Grid item xs={queryNum === "1" ? 12 : 6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: 'solid 1px lightgray',
              }}
            >
              <Typography
                variant='h6'
                fontWeight={600}
                sx={{
                  pb: 1
                }}
              >
                Last Quarter
              </Typography>

              <Box
                display='flex'
                justifyContent='space-around'
                width='100%'
                maxWidth='600px'
                sx={{
                  pb: 2
                }}
              >
                <Box display='flex' flexDirection='column' justifyContent='center'>
                  <PieChart
                    colors={["#6E37FA", "#F40953", "#FF9408", "#32BBB9" ]}
                    series={[
                      {
                        data: Object.entries(percentData).map(([key, values], ind2) => {
                          return {
                            id: ind2,
                            value: values[0],
                            label: key,
                          }
                        })
                      }
                    ]}
                    margin={{ right: 0 }}
                    width={175}
                    height={175}
                    slotProps={{legend: {hidden: true}}}
                  />
                  <Typography variant='body1' color='black' textAlign='center'>Percent of $</Typography>
                </Box>

                <Box display='flex' flexDirection='column' justifyContent='center'>
                  <PieChart
                    colors={["#6E37FA", "#F40953", "#FF9408", "#32BBB9" ]}
                    series={[
                      {
                        data: Object.entries(percentData).map(([key, values], ind2) => {
                          return {
                            id: ind2,
                            value: values[2],
                            label: key,
                          }
                        })
                      }
                    ]}
                    margin={{ right: 0 }}
                    width={175}
                    height={175}
                    slotProps={{legend: {hidden: true}}}
                  />
                  <Typography variant='body1' color='black' textAlign='center'>Percent of Impressions</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6}
              sx={{
                display: queryNum === '1' ? 'none' : 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='h6'
                fontWeight={600}
                sx={{
                  pb: 1
                }}
              >
                Re-optimized Next Quarter
              </Typography>

              <Box
                display='flex'
                justifyContent='space-around'
                width='100%'
                maxWidth='600px'
                sx={{
                  pb: 2
                }}
              >
                <Box display='flex' flexDirection='column' justifyContent='center'>
                  <PieChart
                    colors={["#6E37FA", "#F40953", "#FF9408", "#32BBB9" ]}
                    series={[
                      {
                        data: Object.entries(percentData).map(([key, values], ind2) => {
                          return {
                            id: ind2,
                            value: values[1],
                            label: key,
                          }
                        })
                      }
                    ]}
                    margin={{ right: 0 }}
                    width={175}
                    height={175}
                    slotProps={{legend: {hidden: true}}}
                  />
                  <Typography variant='body1' color='black' textAlign='center'>Percent of $</Typography>
                </Box>

                <Box display='flex' flexDirection='column' justifyContent='center'>
                  <PieChart
                    colors={["#6E37FA", "#F40953", "#FF9408", "#32BBB9" ]}
                    series={[
                      {
                        data: Object.entries(percentData).map(([key, values], ind2) => {
                          return {
                            id: ind2,
                            value: values[3],
                            label: key,
                          }
                        })
                      }
                    ]}
                    margin={{ right: 0 }}
                    width={175}
                    height={175}
                    slotProps={{legend: {hidden: true}}}
                  />
                  <Typography variant='body1' color='black' >Percent of Impressions</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box
            display='flex'
            justifyContent={'space-around'}
            width='100%'
            sx={{
              p: 1,
              maxWidth: '700px',
              backgroundColor: 'white',
              border: 'solid 1px lightgray',
              pl: 5,
              pr: 5,
              mt: 2,
            }}
          >
            {
              [
                ['TV Linear', "#6E37FA"],
                ['CTV', "#F40953"],
                ['Computer', "#FF9408"],
                ['Mobile', "#32BBB9"]
              ].map((val) => {
                return (
                  <Box key={val[0]} display='flex' alignItems={'center'}>
                    <Box
                      height={20}
                      width={20}
                      sx={{backgroundColor: val[1], mr: 1}}
                    />
                    <Typography variant='body1' color='black'>
                      {val[0]}
                    </Typography>
                  </Box>
                )
              })
            }
          </Box>

          <Grid container sx={{mt: 4, mb: 4}} className='nielsen-tables'>
            <Grid item xs={queryNum === '1' ? 12 : 6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRight: queryNum === '1' ? 'none' : 'solid 1px lightgray',
              }}
            >
              {
                // Table for Statistics
              }
              <Table sx={{maxWidth: 300}}>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell sx={{height: '79.5px'}}>{" "}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Target Reach</TableCell>
                    <TableCell>61.6%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Avg. Frequency</TableCell>
                    <TableCell>27.8</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ROAS</TableCell>
                    <TableCell>{`$${Math.round(ROAS[0]*100)/100}`}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important'
                      }}
                    >
                      {"# Publishers"}
                    </TableCell>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important',
                        borderBottom: '1px solid lightgray !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TV Linear</TableCell>
                    <TableCell>{numPublishers['LINEAR'][0]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CTV</TableCell>
                    <TableCell>{numPublishers['CTV'][0]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Computer</TableCell>
                    <TableCell>{numPublishers['COMPUTER'][0]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mobile</TableCell>
                    <TableCell>{numPublishers['MOBILE'][0]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>{numPublishers['TOTAL'][0]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={6}
              sx={{
                display: queryNum === '1' ? 'none' : 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {
                // Table for Statistics
              }
              <Table sx={{maxWidth: 400}}>
                <TableHead>
                  <TableRow>
                    <TableCell>{" "}</TableCell>
                    <TableCell>{" "}</TableCell>
                    <TableCell width='100px' sx={{textAlign: 'center'}}>{"Percent Improvement"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Target Reach</TableCell>
                    <TableCell>86.0%</TableCell>
                    <TableCell>+40%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Avg. Frequency</TableCell>
                    <TableCell>{`${Math.round(totalImpressions[1]/(256041767*0.86)*10)/10}`}</TableCell>
                    <TableCell>{`${Math.round((totalImpressions[1]/(256041767*0.86)-27.8)/27.8*100*10)/10}%`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ROAS</TableCell>
                    <TableCell>{`$${Math.round(ROAS[1]*100)/100}`}</TableCell>
                    <TableCell>{`+${Math.round((ROAS[1]-ROAS[0])/ROAS[0]*10000)/100}%`}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important'
                      }}
                    >
                      {"# Publishers"}
                    </TableCell>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important',
                        borderBottom: '1px solid lightgray !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                    <TableCell sx={{
                        backgroundColor: 'inherit !important',
                        border: 'none !important',
                        borderBottom: '1px solid lightgray !important'
                      }}
                    >
                      {" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>TV Linear</TableCell>
                    <TableCell>{numPublishers['LINEAR'][1]}</TableCell>
                    <TableCell>{`${numPublishers['LINEAR'][2]}%`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CTV</TableCell>
                    <TableCell>{numPublishers['CTV'][1]}</TableCell>
                    <TableCell>{`+${numPublishers['CTV'][2]}%`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Computer</TableCell>
                    <TableCell>{numPublishers['COMPUTER'][1]}</TableCell>
                    <TableCell>Infinite</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mobile</TableCell>
                    <TableCell>{numPublishers['MOBILE'][1]}</TableCell>
                    <TableCell>{`+${numPublishers['MOBILE'][2]}%`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>{numPublishers['TOTAL'][1]}</TableCell>
                    <TableCell>{`+${numPublishers['TOTAL'][2]}%`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

            </Grid>
          </Grid>
        </Box>
      }
    </Box>
  )
}

export default Nielsen;

async function fetchNielsenData(): Promise<string[][] | null> {
  try {
    const sheetUrl = process.env.REACT_APP_NIELSEN_DATA;

    if (!sheetUrl) {
      console.warn('Google Sheet URL not found in environment variables');
      return null;
    }

    const requestOptions: RequestInit = {
      method: 'GET',
    };

    const response = await fetch(sheetUrl, requestOptions);

    if (!response.ok) {
      console.warn(`Failed to fetch data: ${response.statusText}`);
      return null;
    }

    const data = await response.json();
    return data.values;
  } catch (error) {
    console.error('Error fetching Google Sheet data:', error);
    return null;
  }
};
